body {
  background-color: #F8F0FF;
}

.landing-page {
  font-family: Roboto, Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  margin-bottom: 40px;
}

h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

h1 {
    color: purple;
}

.tagline {
  font-size: 24px;
  color: #666;
}

.main-text {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
}

.product-boxes {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #E6E6FA;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 200px;
  width: 200px;
  background:linear-gradient(145deg, #ffffff, #f0f0f0);
  position: relative;
}

.box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.box svg {
  font-size: 2.5rem;
  margin: 20px 20px;
  color: purple;
}

.box span {
  font-weight: bold;
  font-size: 1.4rem;
}

.box p {
  font-size: 1rem;
  color: #666;
  text-align: center;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: purple;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 100;
}

footer nav a {
  margin-left: 20px;
  color: white;
  text-decoration: none;
}

/* Ensure links wrapping boxes reset default styles */
.box-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.coming-soon-stamp {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #ffcc00;
  color: #000;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 3px;
  font-size: smaller;
}

/* New styling for the about section to create a clear section break */
.about-section {
  margin-top: 60px;  /* Space above the section */
  margin-bottom: 60px; /* Space below the section */
  font-weight: 300;
  line-height: 1.4;
  font-family: Lato, Arial, sans-serif;
  font-size: large;
  background-color: white;
  padding: 10px 30px;
  border-radius: 1.5px;
  border: 1px solid purple;
}

.styled-hr {
  margin-top: 50px;  /* Add 30px of margin above */
  border: none;      /* Remove default border */
  height: 2px;       /* Set the desired thickness */
  background: linear-gradient(to right, #ccc, #ddd, #ccc); /* A subtle gradient for a prettier look */
}
